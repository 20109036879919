import React from 'react';
import PropTypes from 'prop-types';

import { FormSpy } from 'react-final-form';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import ErrorMessage from '../components/entities/ErrorMessage';

const FormError = ({ render }) => {
  const { strings } = useI18Next();

  return (
    <FormSpy subscription={
      {
        submitError: true, error: true, submitFailed: true, hasValidationErrors: true
      }
    }>
      {({
        submitError, error, submitFailed, hasValidationErrors
      }) => {
        if (submitFailed && hasValidationErrors) {
          return <ErrorMessage error={error || strings('ui.component.formError')}/>;
        } else if (error || submitError) {
          if (render) {
            return render({ error: error || submitError });
          }
          return <ErrorMessage error={error || submitError}/>;
        }
        return null;
      }}
    </FormSpy>
  );
};

FormError.propTypes = {
  render: PropTypes.func
};

export default FormError;
