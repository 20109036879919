import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import React from 'react';

const ErrorAlert = ({ error, title, message, children }: {
  error: Error, title?: string, message?: string, children?: React.ReactNode
}) => {
  if (!error) return null;

  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{children || message || error.message}</AlertDescription>
    </Alert>
  );
};

export default ErrorAlert;
