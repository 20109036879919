import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { Input as ChakraInput } from '@chakra-ui/react';
import SuiForm from '../../components/collections/Form';
import Input from '../../components/elements/Input';

import FieldError from './FieldError';
import FieldLabel from "./FieldLabel";

const TextField = (props) => {
  const {
    name,
    label,
    validate,
    format,
    formatOnBlur = false,
    parse,
    fluid = true,
    inputProps,
    optional,
    wrapLabel,
    info,
    disabled,
    chakra,
    ...rest
  } = props;

  const FieldCmp = chakra ? React.Fragment : SuiForm.Field;
  const InputCmp = chakra ? ChakraInput : Input;

  return (
    <Field name={name} validate={validate} format={format} parse={parse} info={info} formatOnBlur={formatOnBlur}>
      {({ input }) => (
        <FieldCmp>
          <FieldLabel label={label} optional={optional} wrapLabel={wrapLabel} disabled={disabled}>
            <InputCmp {...input} fluid={fluid} type='text' {...rest} {...inputProps} disabled={disabled} />
            {info && !chakra && <p className='text--muted'>{info}</p>}
            <FieldError name={name} chakra={chakra} />
          </FieldLabel>
        </FieldCmp>
      )}
    </Field>
  );
};
TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  format: PropTypes.func,
  parse: PropTypes.func,
  fluid: PropTypes.bool,
  inputProps: PropTypes.object,
  optional: PropTypes.bool,
  wrapLabel: PropTypes.bool,
  info: PropTypes.string,
  formatOnBlur: PropTypes.bool,
  disabled: PropTypes.bool,
  chakra: PropTypes.bool,
};

export default TextField;
