/* globals Image, atob, Blob, FileReader */
import resizeImage from 'smart-img-resize';

const ERROR_CODES = {
  FILE_SIZE_LIMIT_EXCEEDED: 'FILE_SIZE_LIMIT_EXCEEDED'
};

function clamp(min, target, max) {
  return Math.max(min, Math.min(target, max));
}

function clampLogicalDimensions(primary, secondary, aspect) {
  const minSecondary = clamp(
    secondary.min,
    primary.min / aspect,
    secondary.max
  );

  const minPrimary = clamp(
    primary.min,
    minSecondary * aspect,
    primary.max
  );

  const maxSecondary = clamp(
    minSecondary,
    Math.min(primary.max / aspect, secondary.natural),
    secondary.max
  );

  const maxPrimary = clamp(
    primary.min,
    maxSecondary * aspect,
    primary.max
  );

  return {
    minPrimary,
    maxPrimary,
    minSecondary,
    maxSecondary
  };
}

function getSafeFileDimensions(cropParams, dimensions) {
  // Maintain aspect ratio while ensuring with and height remain within target ranges
  const { box, image } = cropParams;

  // If canvas is rotated sideways, the natural width and height are flipped
  const naturalHeight = image.rotate === 90 || image.rotate === 270 ? image.naturalWidth : image.naturalHeight;
  const naturalWidth = image.rotate === 90 || image.rotate === 270 ? image.naturalHeight : image.naturalWidth;

  const maxAspect = dimensions.maxHeight / dimensions.minWidth;
  const minAspect = dimensions.minHeight / dimensions.maxWidth;

  const targetAspect = clamp(minAspect, box.height / box.width, maxAspect);
  const naturalAspect = naturalHeight / naturalWidth;

  let minHeight;
  let maxHeight;
  let minWidth;
  let maxWidth;

  if (targetAspect > naturalAspect) {
    ({
      minPrimary: minHeight,
      maxPrimary: maxHeight,
      minSecondary: minWidth,
      maxSecondary: maxWidth
    } = clampLogicalDimensions(
      { min: dimensions.minHeight, max: dimensions.maxHeight, natural: naturalHeight },
      { min: dimensions.minWidth, max: dimensions.maxWidth, natural: naturalWidth },
      targetAspect
    ));
  } else {
    ({
      minPrimary: minWidth,
      maxPrimary: maxWidth,
      minSecondary: minHeight,
      maxSecondary: maxHeight
    } = clampLogicalDimensions(
      { min: dimensions.minWidth, max: dimensions.maxWidth, natural: naturalWidth },
      { min: dimensions.minHeight, max: dimensions.maxHeight, natural: naturalHeight },
      1 / targetAspect
    ));
  }

  return {
    minWidth, maxWidth, minHeight, maxHeight
  };
}

function getFileTypesForEncode(types) {
  const result = [];
  const hasPng = types.find(type => /.*png/.test(type));
  const hasJpeg = types.find(type => /.*jpe?g/.test(type));

  if (hasPng) result.push('image/png');
  if (hasJpeg) result.push('image/jpeg');
  // fall back to jpeg because png and jpeg are the only two with decent support
  if (!result.length) result.push('image/jpeg');

  return result;
}

function getBlobFromBase64String(base64String, properties) {
  const binStr = atob(base64String);
  const len = binStr.length;
  const byteArray = new Uint8Array(len);

  for (let i = 0; i < len; i += 1) {
    byteArray[i] = binStr.charCodeAt(i);
  }

  return new Blob([byteArray], properties);
}

async function getBase64StringFromBlob(blob) {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = () => {
      res(reader.result);
    };
    reader.onerror = (err) => {
      rej(err);
    };

    reader.readAsDataURL(blob);
  });
}

export function getMimeTypeFromFileUrl(base64ImageUrl) {
  const MIME_TYPE_REGEX = /^data:([^;]+)/;
  const [header] = base64ImageUrl.split(',');
  const [, mimeType] = MIME_TYPE_REGEX.exec(header) || [];
  return mimeType;
}

function getBlobFromBase64ImageUrl(base64ImageUrl) {
  const [, base64String] = base64ImageUrl.split(',');
  const mimeType = getMimeTypeFromFileUrl(base64ImageUrl);
  return getBlobFromBase64String(base64String, { type: mimeType });
}

function getImageFileUrlAsFile(fileUrl) {
  return getBlobFromBase64ImageUrl(fileUrl);
}

const freeEventLoop = () => new Promise(res => setTimeout(res, 1));

const getImageFileUrlDimensions = async fileUrl => new Promise((res, rej) => {
  const image = new Image();
  image.src = fileUrl;

  image.onload = () => {
    const { width, height } = image;
    res({ width, height });
  };

  image.onerror = (error) => {
    rej(error);
  };
});

const getDataUrlFromCanvas = async (canvas, mimeType, quality) => {
  // Prefer toBlob because it doesn't tie up the main thread
  if (!canvas.toBlob) return canvas.toDataURL(mimeType, quality);

  // TODO: Determine if this needs to be async.
  // I don't think so but I don't have the time to thoroughly test it and it works right now
  /* eslint-disable-next-line no-async-promise-executor */
  const blob = await new Promise(async (res) => {
    canvas.toBlob(res, mimeType, quality);
  });

  return getBase64StringFromBlob(blob);
};

const getDataUrlAtScale = async (cropper, scale, dimensions, mimeType) => {
  const {
    maxWidth, maxHeight, minWidth, minHeight
  } = dimensions;
  const canvas = cropper.getCroppedCanvas({
    height: maxHeight - ((1 - scale) * (maxHeight - minHeight)),
    width: maxWidth - ((1 - scale) * (maxWidth - minWidth)),
    imageSmoothingEnabled: true,
    imageSmoothingQuality: 'high'
    // fillColor: fillColor // <- Force background for transparent images
  });
  return getDataUrlFromCanvas(canvas, mimeType, scale);
};

const getScaledDataUrlUnderSize = async (cropper, dimensions, maxFileSize, mimeTypes, scale = 1) => {
  if (scale < 0) {
    if (mimeTypes.length > 1) return getScaledDataUrlUnderSize(cropper, dimensions, maxFileSize, mimeTypes.slice(1));
    const err = new Error('Unable to scale file under size limits');
    err.code = ERROR_CODES.FILE_SIZE_LIMIT_EXCEEDED;
    throw err;
  }

  await freeEventLoop(); // don't lock up main thread now that we're synchronously getting the data url
  const dataUrl = await getDataUrlAtScale(cropper, scale, dimensions, mimeTypes[0]);
  const file = getBlobFromBase64ImageUrl(dataUrl);
  if (file.size <= maxFileSize) return dataUrl;

  return getScaledDataUrlUnderSize(cropper, dimensions, maxFileSize, mimeTypes, scale - 0.125);
};

const formatFileSize = bytes => `${Math.round(bytes / 10.24) / 100}kb`;

const formatFileTypes = fileTypes => fileTypes && [].concat(fileTypes).map(t => t.split('/').pop()).join(',');

const formatFileTypesVerbose = fileTypes => {
  if (!fileTypes) return fileTypes;
  const items = fileTypes.map(t => t.split('/').pop());
  if (items.length === 1) return items[0];
  if (items.length === 2) return items.join(' or ');
  items.push('or ' + items.pop());
  return items.join(', ');
};

const formatFileDimensions = (dimensions) => {
  if (!dimensions) return dimensions;
  const parts = [].concat(dimensions).map(d => `${d.w}x${d.h}px`);
  if (parts[0] === parts[1]) return parts[0];
  return parts.join('–');
};

/**
 * Automatically resize image to fit fileTypeInfo restrictions
 * @param {ImageFile} file
 * @param {FileTypeInfo} fileTypeInfo
 * @param {number} scale - float from 0 (image dimensions as small as possible)
 *                         to 1 (image dimensions as large as possible, capped by input image size).
 * @return {Promise<Base64ImageUrl>}
 */
const autoCrop = async (file, fileTypeInfo, scale = 0, allowUpscaling = true) => {
  const {
    restrictions: {
      types, dimensions: {
        minWidth, minHeight, maxWidth, maxHeight
      }
    }
  } = fileTypeInfo;

  const dataUrl = await getBase64StringFromBlob(file);
  const { width, height } = await getImageFileUrlDimensions(dataUrl);
  const validWidth = width >= minWidth && width <= maxWidth;
  const validHeight = height >= minHeight && width <= maxHeight;
  const validType = types.includes(getMimeTypeFromFileUrl(dataUrl));

  // No need to crop
  if (validWidth && validHeight && validType) return dataUrl;

  // What are the width and height limits that satisfy
  // 1) original image aspect ratio
  // 2) fileTypeInfo dimension restrictions
  // 3) no upscaling -- this isn't required because we check if it's too small when the file is first read

  const heightAtMinWidth = height * (minWidth / width);
  const widthAtMinHeight = width * (minHeight / height);

  const widthLimited = heightAtMinWidth > minHeight;

  const minTargetWidth = widthLimited ? minWidth : widthAtMinHeight;
  const minTargetHeight = widthLimited ? heightAtMinWidth : minHeight;

  let targetWidth;
  let targetHeight;

  if (allowUpscaling) {
    targetWidth = lerp(minTargetWidth, maxWidth, scale);
    targetHeight = lerp(minTargetHeight, maxHeight, scale);
  } else {
    targetWidth = Math.min(width, lerp(minTargetWidth, maxWidth, scale));
    targetHeight = Math.min(height, lerp(minTargetHeight, maxHeight, scale));
  }

  return new Promise((res, rej) => {
    resizeImage(
      file,
      {
        outputFormat: getFileTypesForEncode(types)[0].split('/').pop(),
        targetWidth,
        targetHeight
      },
      (err, b64Image) => {
        if (err) return rej(err);
        return res(b64Image);
      }
    );
  });
};

const lerp = (a, b, t) => {
  return a + (t * (b - a));
};

export {
  getSafeFileDimensions,
  getFileTypesForEncode,
  getImageFileUrlAsFile,
  getImageFileUrlDimensions,
  getScaledDataUrlUnderSize,
  getBase64StringFromBlob,
  formatFileSize,
  formatFileTypes,
  formatFileTypesVerbose,
  formatFileDimensions,
  autoCrop,
  ERROR_CODES
};
