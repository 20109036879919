import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import SuiForm from '../../components/collections/Form';

import FieldError from './FieldError';
import FieldLabel from './FieldLabel';

const TextArea = (props) => {
  const {
    name, label, validate, optional, parse, format, allowNull, wrapLabel, info, ...rest
  } = props;
  return (
    <Field
      name={name} validate={validate} parse={parse} format={format} allowNull={allowNull}
    >
      {({ input }) => (
        <SuiForm.Field>
          <FieldLabel label={label} optional={optional} wrapLabel={wrapLabel}>
            <SuiForm.TextArea {...input} {...rest} autoHeight />
            {info && <p className='text--muted'>{info}</p>}
            <FieldError name={name} />
          </FieldLabel>
        </SuiForm.Field>
      )}
    </Field>
  );
};
TextArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  parse: PropTypes.func,
  format: PropTypes.func,
  optional: PropTypes.bool,
  allowNull: PropTypes.bool,
  wrapLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
};

export default TextArea;
